var AccountSettingsTileJS = new function () {
    var initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            $(document).ready(function () {
                $(".changeLoginInformation-cancel").hide();
                $(".changeLoginInformation-save").hide();
                $(".changeRecoveryInformation-cancel").hide();
                $(".changeRecoveryInformation-save").hide();
                $(".changeContactInformation-cancel").hide();
                $(".changeContactInformation-save").hide();
                $("#personalEmailError").hide();
                $("#workEmailError").hide();
                $("#recoveryEmailError").hide();
                $("#recoveryPhoneError").hide();
                $('#cannotChangeEmailError').hide();
                $('#inUseEmailError').hide();
                $('#currentPasswordMismatchError').hide();
                $('#passwordComplexityError').hide();
                $('#formerPasswordError').hide();
                $('#workPhoneError').hide();
                $('#personalPhoneError').hide();
                $('input[name ="WorkEmail"').prop('readonly', true);
                $('input[name ="WorkCellPhone"').prop('readonly', true);
                $('input[name ="PersonalEmail"').prop('readonly', true);
                $('input[name ="PersonalCellPhone"').prop('readonly', true);
                $('.contact-checkbox').addClass('account-settings-checkbox-no-click');
            });

            $(".changeLoginInformation-edit").click(function () {
                if (!$("#ChangeLoginInformationDropdown").hasClass("show")) {
                    $(".changeLoginInformation-cancel").show();
                    $(".changeLoginInformation-save").show();
                    $(".changeLoginInformation-edit").hide();
                    $("#ChangeLoginInformationDropdown").collapse("show");
                    $("#loginPassword").prop('readonly', false);
                    $('#loginPassword').removeAttr('placeholder');
                }
            });

            $(".changeLoginInformation-cancel").click(function () {
                if ($("#ChangeLoginInformationDropdown").hasClass("show")) {
                    $(".changeLoginInformation-cancel").hide();
                    $(".changeLoginInformation-save").hide();
                    $(".changeLoginInformation-edit").show();
                    $("#ChangeLoginInformationDropdown").collapse("hide");
                    $("#loginPassword").prop('readonly', true);
                }
            });

            $(".changeLoginInformation-save").click(function () {
                if (!$(this).hasClass('account-settings-update-action-disabled')) {
                    $.ajax({
                        type: 'POST',
                        contentType:'application/json',
                        url: 'UpdateUserPassword',
                        data: JSON.stringify({
                            currentPassword: $('#loginPassword').val(),
                            newPassword: $('#newPassword').val()
                        }),
                        complete: function (updatePasswordResult) {
                            $('#currentPasswordMismatchError').hide();
                            $('#passwordComplexityError').hide();
                            $('#formerPasswordError').hide();
                            if (updatePasswordResult.responseJSON.DoesNotMatchCurrentPassword) {
                                $('#currentPasswordMismatchError').show();
                            }
                            else if (updatePasswordResult.responseJSON.UpdatePasswordResult && updatePasswordResult.responseJSON.UpdatePasswordResult.IsTooSimple) {
                                $('#passwordComplexityError').show();
                            }
                            else if (updatePasswordResult.responseJSON.UpdatePasswordResult && updatePasswordResult.responseJSON.UpdatePasswordResult.IsFormerPassword) {
                                $('#formerPasswordError').show();
                            }
                            else {
                                $(".changeLoginInformation-cancel").hide();
                                $(".changeLoginInformation-save").hide();
                                $(".changeLoginInformation-edit").show();
                                $("#ChangeLoginInformationDropdown").collapse("hide");
                                $("#loginPassword").prop('readonly', true);
                            }
                        }
                    });
                }
            });

            $(".changeRecoveryInformation-save").click(function () {
                if (!$(this).hasClass('account-settings-update-action-disabled')) {
                    $.ajax({
                        type: 'POST',
                        contentType: 'application/json',
                        url: 'UpdateRecoveryInfo',
                        data: JSON.stringify({
                            newEmail: $('#recoveryEmail').val(),
                            newPhone: $('#recoveryPhoneNumber').val()
                        }),
                        complete: function (updateRecoveryResult) {
                            $('#cannotChangeEmailError').hide();
                            $('#recoveryEmailError').hide();
                            $('#inUseEmailError').hide();
                            if (updateRecoveryResult.responseJSON.CannotChangeEmail) {
                                $('#cannotChangeEmailError').show();
                            }
                            else if (updateRecoveryResult.responseJSON.EmailIsEmpty) {
                                $('#recoveryEmailError').show();
                            }
                            else if (updateRecoveryResult.responseJSON.EmailIsTaken) {
                                $('#inUseEmailError').show();
                            }
                            else {
                                $(".changeRecoveryInformation-cancel").hide();
                                $(".changeRecoveryInformation-save").hide();
                                $(".changeRecoveryInformation-edit").show();
                                $('#recoveryEmail').prop('readonly', true);
                                $('#recoveryPhoneNumber').prop('readonly', true);
                            }
                        }
                    });
                }
            });

            $(".contact-checkbox").click(function (e) {
                if ($(this).hasClass('account-settings-checkbox-no-click')) {
                    e.preventDefault();
                    return false;
                }
                else {
                    CheckContactValidity();
                }
            });

            $(".changeRecoveryInformation-edit").click(function () {
                $(".changeRecoveryInformation-cancel").show();
                $(".changeRecoveryInformation-save").show();
                $(".changeRecoveryInformation-edit").hide();
                $('#recoveryEmail').prop('readonly', false);
                $('#recoveryPhoneNumber').prop('readonly', false);
            });

            $(".changeRecoveryInformation-cancel").click(function () {
                $(".changeRecoveryInformation-cancel").hide();
                $(".changeRecoveryInformation-save").hide();
                $(".changeRecoveryInformation-edit").show();
                $('#recoveryEmail').prop('readonly', true);
                $('#recoveryPhoneNumber').prop('readonly', true);
            });

            $(".changeContactInformation-edit").click(function () {
                $(".changeContactInformation-cancel").show();
                $(".changeContactInformation-save").show();
                $(".changeContactInformation-edit").hide();
                $('input[name ="WorkEmail"').prop('readonly', false);
                $('input[name ="WorkCellPhone"').prop('readonly', false);
                $('input[name ="PersonalEmail"').prop('readonly', false);
                $('input[name ="PersonalCellPhone"').prop('readonly', false);
                $('.contact-checkbox').removeClass('account-settings-checkbox-no-click');
            });

            $(".changeContactInformation-cancel").click(function () {
                $(".changeContactInformation-cancel").hide();
                $(".changeContactInformation-save").hide();
                $(".changeContactInformation-edit").show();
                $('input[name ="WorkEmail"').prop('readonly', true);
                $('input[name ="WorkCellPhone"').prop('readonly', true);
                $('input[name ="PersonalEmail"').prop('readonly', true);
                $('input[name ="PersonalCellPhone"').prop('readonly', true);
                $('.contact-checkbox').addClass('account-settings-checkbox-no-click');
            });

            $('#loginPassword').keyup(function () {
                validCurrentPassword = $(this).val();

                CheckLoginValidity();
            })

            $("#newPassword").keyup(function () {
                var pw = $(this).val();
                validPasswordReentry = pw == $("#retypePassword").val();
                var validCount = 0; //count number of valid complexity requirements
                if (pw.length < 8) {
                    //not long enough, auto fail
                    validPassword = false
                    CheckLoginValidity();
                    return
                }
                if (pw.match(new RegExp('[A-Z]'))) {
                    validCount++;
                }
                if (pw.match(new RegExp('[a-z]'))) {
                    validCount++;
                }
                if (pw.match(new RegExp('[0-9]'))) {
                    validCount++;
                }
                if (pw.match(new RegExp('[^a-zA-Z0-9]'))) {
                    validCount++;
                }
                if (validCount >= 3) {
                    //password is valid
                    validPassword = true;
                }
                CheckLoginValidity();
            });

            $("#retypePassword").keyup(function () {
                if ($(this).val() == $("#newPassword").val()) {
                    validPasswordReentry = true;
                }
                else {
                    validPasswordReentry = false;
                }
                CheckLoginValidity();
            });

            $("#recoveryEmail").keyup(function () {
                validRecoveryEmail = IsEmail($(this).val());
                if (validRecoveryEmail) {
                    $('#recoveryEmailError').hide();
                }
                else {
                    $('#recoveryEmailError').show();
                }
                CheckRecoveryValidity();
            });
            $("#recoveryPhoneNumber").keyup(function () {
                CheckRecoveryValidity();
            });


            $('input[name ="PersonalEmail"').change(function () {
                CheckContactValidity();
            });

            $('input[name ="PersonalCellPhone"').change(function () {
                CheckContactValidity();
            });

            $('input[name ="WorkCellPhone"').change(function () {
                CheckContactValidity();
            });

            $('input[name ="WorkEmail"').change(function () {
                CheckContactValidity();
            });

            function IsEmail(email) {
                var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                return regex.test(email);
            }

            function CheckLoginValidity() {
                if (validPassword && validPasswordReentry && validCurrentPassword) {
                    $(".changeLoginInformation-save").removeClass("account-settings-update-action-disabled");
                }
                else {
                    $(".changeLoginInformation-save").addClass("account-settings-update-action-disabled");
                }
            }

            function CheckRecoveryValidity() {
                if (validRecoveryEmail && validRecoveryPhone) {
                    $(".changeRecoveryInformation-save").removeClass("account-settings-update-action-disabled");
                }
                else {
                    $(".changeRecoveryInformation-save").addClass("account-settings-update-action-disabled");
                }
            }

            function CheckContactValidity() {
                //use the partial checking logic
                if ($("#personal-email-error").is(':empty') && $("#personal-phone-error").is(':empty') && $("#work-email-error").is(':empty') && $("#work-phone-error").is(':empty')) {
                    $(".changeContactInformation-save").removeClass("account-settings-update-action-disabled");
                    $(".changeContactInformation-save").removeAttr("disabled", "disabled");
                }
                else {
                    $(".changeContactInformation-save").addClass("account-settings-update-action-disabled");
                    $(".changeContactInformation-save").attr("disabled", "disabled");
                }
            }

            var validCurrentPassword = false;
            var validPassword = false;
            var validPasswordReentry = false;
            var validRecoveryEmail = true;
            var validRecoveryPhone = true;

            initialized = true;
        }
    }
}();