let formTileJS = (function () {
    let initiated = false;
    return {
        init: function (pageID, partialViewURL, instanceName) {
            if (initiated) {
                return;
            }

            $(window).on('resize', function() {
                if(window.innerWidth > 1199.98) {
                    $(".forms-overlay").css('display', '');
                    $(".forms-mobile-button-container").css('display', '');
                    $("html").css("overflow", "");
                    $(".forms-mobile-search-container").css("display", "");
                    $(".forms-tile").css("margin-top", "");
                }
            });

            $(document).ready(function () {
                $("#" + instanceName + "FormTypes").change(function () {
                    const categoryType = $("#" + instanceName + "FormTypes").val();
                    UpdateLibraryFilter(categoryType);
                });
            });

            function UpdateLibraryFilter(categoryType) {
                const participantID = getUrlVars()["participantID"];
                const pageTileID = pageID;

                $.ajax({
                    type: "POST",
                    url: partialViewURL,
                    data: { participantID: participantID, pageTileID: pageTileID, CategoryType: categoryType },
                    success: function (data) {
                        $("#" + instanceName + "formsgrid").html(data);
                        getFormTileUserInput();
                    }
                });
            }

            // Read a page's GET URL variables and return them as an associative array.
            function getUrlVars() {
                let vars = [], hash;
                let hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
                for (var i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split("=");
                    vars.push(hash[0]);
                    vars[hash[0]] = hash[1];
                }
                return vars;
            }

            function getFormTileUserInput() {
                /*User Search*/
                const userInput = $('.forms-search-field');
                const formLinksContainer = $(".forms-link-container");

                userInput.keyup(function () {
                    const word = this.value;

                    //Sync the 2 search fields
                    $(".forms-search-field").val(word);

                    for(let i = 0; i < formLinksContainer.length; i++){
                        const formsLink = $(formLinksContainer[i]).children('.forms-link').text().trim().toLowerCase()

                        if(!formsLink.includes(word)) {
                            $(formLinksContainer[i]).addClass('d-none');
                        } else {
                            $(formLinksContainer[i]).removeClass('d-none');
                        }
                    }
                });
            }

            getFormTileUserInput();

            $('.forms-mobile-filter-button').click(function() {
                $(".forms-overlay").css("display", "block");
                $(".forms-mobile-button-container").css("display", "none");
                $("html").css("overflow", "hidden");
            });

            $('.forms-overlay-close-button').click(function() {
                $(".forms-overlay").css("display", "");
                $(".forms-mobile-button-container").css("display", "");
                $("html").css("overflow", "");
            });

            $('.forms-overlay-list-item').click(function() {
                const categoryType = $(this).attr('id');
                $("html").css("overflow", "");
                $("#" + instanceName + "FormTypes").val(categoryType);
                UpdateLibraryFilter(categoryType);
                $('.forms-overlay-close-button').trigger('click');
            });

            $('.forms-mobile-search-button').click(function() {
                $(".forms-mobile-button-container").css("display", "none");
                $(".forms-mobile-search-container").css("display", "flex");

                $(".forms-mobile-search-container").find('.forms-search-field').focus();
            });

            $('.forms-mobile-search-close').click(function() {
                $(".forms-mobile-button-container").css("display", "");
                $(".forms-mobile-search-container").css("display", "");
            });

            initiated = true;
        }
    }
})();