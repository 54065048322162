var featuredFormTileJS = (function () {
    return {
        initialized: false,
        init: function() {
           if (this.initialized) {
                return;
            }

            $(".ul-form li a").each(function () {
                var text = $(this).text();
                if (text.length > 30) {
                    $(this).text(text.substring(0, 30) + " ...");
                }
            });

            this.initialized = true;
        }
    }
})();
