var featuredFaqTileJS = (function () {
    var initialized = false;

    return {
        Init: function (instanceName) {
            if (initialized) {
                return;
            }

            //$('.featured-faq-tile-question').click(function (e) {
            //    e.preventDefault();
            //    var myAnswer = $(this).parent().next().get(0);

            //    myAnswer.classList.toggle('show');
            //});

            initialized = true;
        }
    }
})();