var CarouselTileJS = new function () {
    var initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            initialized = true;

            //line-clamp doesn't work on IE so just always show the full content
            if (window.navigator.userAgent.indexOf("MSIE ") != -1 || window.navigator.userAgent.match(/Trident.*rv\:11\./)) {
                return;
            }

            $(document).ready(showMoreButtons);

            $(window).resize(function () {
                $('.dashboard-sub-card').removeClass('expandable');
                showMoreButtons();
            });

            function showMoreButtons() {
                //Hide show more button if the text is larger than 5 lines
                $('.dashboard-sub-text').each(function () {
                    //Height of div
                    var divheight = $(this).height();
                    //Line height
                    var lineheight = parseInt($(".dashboard-sub-text").css('line-height'), 10);

                    //Show read more and clamp line if its larger than 5 lines
                    if ((Math.round(divheight / lineheight)) > 5) {
                        $(this).closest('.dashboard-sub-card').addClass('expandable');
                    } else {
                        $(this).closest('.dashboard-sub-card').removeClass('expandable');
                    }
                });
            }

            //Show entire text and show "show less" button
            $(".dashboard-sub-read").click(function () {
                $(this).closest('.dashboard-sub-card').addClass('expanded');
            });

            //Truncate text and show "show more button"
            $(".dashboard-sub-less").click(function () {
                $(this).closest('.dashboard-sub-card').removeClass('expanded');
            });
        }
    }
}();