var currentSnapshotBreakdownModule = (function () {

    var myIdentifier = null;

    var myLabelListArray = null;
    var myBackgroundColorArray = null;
    var myValueListArray = null;
    var myGrandTotalText = null;

    var buildChart = function () {
        var ctx = document.getElementById("myChart-" + myIdentifier).getContext('2d');

        var myChart = new Chart(ctx, {
            type: 'doughnut',
            legend: false,
            data: {
                labels: myLabelListArray,
                datasets: [{
                    backgroundColor: myBackgroundColorArray,
                    data: myValueListArray
                }]
            },
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var labelInfo = data.labels[tooltipItem.index];
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            return labelInfo + ": " + Math.round(currentValue) + "%";
                        }
                    }
                }
            },
            plugins: [{
                beforeDraw: function (chart) {

                    var width = chart.chart.width,
                        height = chart.chart.height,
                        ctx = chart.chart.ctx;

                    ctx.restore();
                    var fontSize = (height / 200);
                    ctx.font = (fontSize * 0.8).toFixed(2) + "em sans-serif";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = "#666"; // $grey-medium
                    var text = [myGrandTotalText],
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;

                    ctx.clearRect(0, 0, width, height);
                    ctx.fillText(text, textX, textY);

                    //ctx.fillText("ANNUAL", textX + (textX * 0.3), textY + (textY / 6));

                    ctx.save();
                }
            }]
        });
    };

    return {
        Init: function (identifier, labelListArray, backgroundColorArray, valueListArray, grandTotalText) {


            myIdentifier = identifier;
            myLabelListArray = labelListArray;
            myBackgroundColorArray = backgroundColorArray;
            myValueListArray = valueListArray;
            myGrandTotalText = grandTotalText;

            $('#printConfirmationStatementButton-' + myIdentifier).click(function (e) {
                e.preventDefault();
                window.print();
            });

            buildChart();
        },
        OnDropdownChange: function () {
            $('#runCompButton-' + myIdentifier).click();
        }
    }
})();