var ContactUsForm = (function () {

    var myInstanceName = undefined,
        initialized = false,
        loginUrlSegment = "/MAIN%20PORTAL/LOGIN",
        submitCaptchaButton = {
            Id: "#submit-captcha-btn",
            Disabled: "disabled"
        },

        init = function (instanceName) {

            if (initialized) {
                return;
            }

            myInstanceName = instanceName;

            //Check if all fields are filled out
            $('#submit-nocaptcha-btn').prop("disabled", true);

            $(".contact-submit-btn").click(function (e) {
                e.preventDefault();
                if ($(this).prop('disabled') == true) {
                    return;
                }
                $(this).prop('disabled', true);

                var contactFormViewModel = {};             
                $.each($(this).closest('form').serializeArray(), function () {
                    if (this.name == 'g-recaptcha-response') {
                        contactFormViewModel['recaptchaResponse'] = $("textarea[name='g-recaptcha-response']").val();
                    }
                    else {
                        contactFormViewModel[this.name] = this.value || '';
                    }
                });

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(contactFormViewModel),
                    contentType: 'application/json'
                }).done(function () {
                    $('#support').modal('hide');
                    $('.contact-us-input').val('');
                    $('.contact-submit-btn').prop('disabled', true);
                    grecaptcha.reset();
                    disableSubmitButton();
                });
            });

            $(document).on('keyup', '.contact-us-input', function (e) {
                $('#submit-nocaptcha-btn').prop("disabled", !!$('.modal.show').find(".contact-us-input").filter(function () { return this && (!this.value || !this.value.trim()); }).length);
            })

            initialized = true;
        },

        disableSubmitButton = function () {
            var index = window.location.pathname.toUpperCase().indexOf(loginUrlSegment);
            let captchaIsOnPage = index !== -1;
            if (captchaIsOnPage) {
                $(submitCaptchaButton.Id).prop(submitCaptchaButton.Disabled, true);
            }
        },

        enableSubmitButton = function () {
            $(submitCaptchaButton.Id).prop(submitCaptchaButton.Disabled, false);
        };

    $(document).ready(function () {
        if (typeof window.matchMedia == 'function'
            && matchMedia('(max-width: 1199.98px)').matches) {
            $('#support').find('.modal-dialog').css('top', $('.header-wrapper').height());
        } else {
            $('#support').find('.modal-dialog').css('top', 'unset');
        }
    });

    //Set how low buttons are pushed down since the header can contain banners, and alerts
    $(window).resize(function () {
        if (typeof window.matchMedia == 'function'
            && matchMedia('(max-width: 1199.98px)').matches) {
            $('#support').find('.modal-dialog').css('top', $('.header-wrapper').height());
        } else {
            $('#support').find('.modal-dialog').css('top', 'unset');
        }
    });


    return {
        Init: init,
        DisableSubmitBtn: disableSubmitButton,
        EnableSubmitBtn: enableSubmitButton,
    };
})();