var ManageBenefitsTileJS = (function () {
    var initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            var currentPage = 0;
            var numPerPage = 10;
            var totalPages;
            sortThisTable();


            $('select').on('change', function () {
                if (this.value == "ALL") {
                    currentPage = 0;
                    numPerPage = 10000000000;
                    $('#life-event-items-scroller').hide();
                    sortThisTable();
                } else {
                    currentPage = 0;
                    numPerPage = this.value;
                    $('#life-event-items-scroller').show();
                    sortThisTable();
                }
            });


            $("#items-per-page-minus").click(function () {
                if (currentPage > 0) {
                    currentPage--;
                    sortThisTable();
                    $(this).removeClass("disabled-icon");
                    $("#items-per-page-plus").removeClass("disabled-icon");

                }
            });

            $("#items-per-page-plus").click(function () {
                if ((currentPage + 1) < (totalPages)) {
                    currentPage++;
                    sortThisTable();
                    $("#items-per-page-minus").removeClass("disabled-icon");

                }
            });

            function sortThisTable() {
                $('table.manage-benefits-table').each(function () {
                    var $table = $(this);
                    $table.bind('repaginate', function () {
                        $table.find('tbody tr').hide().slice(currentPage * numPerPage, (currentPage + 1) * numPerPage).show();
                    });
                    $table.trigger('repaginate');
                    var numRows = $table.find('tbody tr').length;
                    var numPages = Math.ceil(numRows / numPerPage);
                    $('#items-per-page-min').html(currentPage + 1);
                    $('#items-per-page-max').html(numPages);
                    totalPages = numPages;
                });
            }

            $(window).resize(function () {
                if (typeof window.matchMedia == 'function' && matchMedia('(min-width: 575.98px)').matches) {
                    $('#mobileEnrollmentHistoryModal').modal('hide');
                }
            });

            initialized = true;
        }
    }
})();