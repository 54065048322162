var acceptableDocumentationTileJS = (function() {
    return {
        initialized: false,
        init: function() {
            if (this.initialized) {
                return;
            }

            var acc = document.getElementsByClassName("accordion");

            for (var i = 0; i < acc.length; i++) {
                acc[i].onclick = function () {
                    this.classList.toggle("active");
                    this.nextElementSibling.classList.toggle("show");
                }
            }

            //Chevron toggle
            function toggleChevron(e) {
                $(e.target)
                    .prev('.panel-heading')
                    .find("span.indicator")
                    .toggleClass('fa-chevron-down fa-chevron-up');
            }

            $('#accordion').on('hide.bs.collapse', toggleChevron);
            $('#accordion').on('show.bs.collapse', toggleChevron);
        }
    }
})();

