var FeaturedTotalCompensationModule = (function () {
    var initialized = false;
    var myInstanceName = null;
    var myDataArray = null;
    var myColorArray = null;

    return {
        Init: function (instanceName, dataArray, colorArray) {
            if (initialized) {
                return;
            }

            myInstanceName = instanceName;
            myDataArray = dataArray;
            myColorArray = colorArray;

           var ctx = $("#myChart-" + instanceName);

            if (ctx && ctx.length) {
                var myPieChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: myDataArray,
                            backgroundColor: myColorArray
                        }],
                    },
                    options: {
                        hover: {
                            mode: false
                        },
                        tooltips: {
                            enabled: false
                        },
                        animation: {
                            duration: 0
                        }
                    }
                });
            }

            initialized = true;
        }
    }
})();