var MyDocumentsTileJS = (function() {
        var initialized = false;

        return {
            Init: function () {
                if (initialized) {
                    return;
                }
                
                //Filter by Search
                $("#my-documents-tile-user-search").keyup(function () {
                    var searchText = $(this).val() && $(this).val().toLowerCase();
                    $(".my-documents-tile-name").each(function (i, v) {
                        getDocumentContainerElement(v).toggleClass('my-documents-tile-document-row-search-filtered-out', !!(searchText && $(v).text() && $(v).text().toLowerCase().indexOf(searchText) == -1));
                    });
                    updateAllAboveMaxClasses();
                });
                /*-------------------------------------*/

                //Filter by Year
                $('#my-documents-tile-select').change(function () {
                    $('.my-documents-tile-document-row-year-filtered-out').removeClass('my-documents-tile-document-row-year-filtered-out');
                    var date = $(".my-documents-tile-date");
                    if ($(this).val() == "This Year") {
                        for (var i = 0; i < date.length; i++) {
                            if (new Date($(date[i]).text()).getFullYear() != new Date().getFullYear()) {
                                getDocumentContainerElement(date[i]).addClass('my-documents-tile-document-row-year-filtered-out');
                            }
                        }
                    } else if ($(this).val() == "Last Year") {
                        for (var i = 0; i < date.length; i++) {
                            if (new Date($(date[i]).text()).getFullYear() != new Date().getFullYear() - 1) {
                                getDocumentContainerElement(date[i]).addClass('my-documents-tile-document-row-year-filtered-out');
                            }
                        }
                    }

                    updateAllAboveMaxClasses();
                });
                /*-------------------------------------*/

                //Sorting
                $('[data-sort-class]').click(function () {
                    var container = $(this).closest('.my-documents-tile-section').find('.my-documents-tile-document-rows');
                    var elems = container.find('.my-documents-tile-document-row');
                    var sortClass = '.' + $(this).data('sort-class');
                    var sortDataType = $(this).data('sort-data-type');
                    var ascending = !$(this).data('ascending');
                    elems.sort(function (a, b) {
                        var sortStringA = $(a).find(sortClass).text();
                        var sortStringB = $(b).find(sortClass).text();
                        if (sortDataType == 'date') {
                            return (ascending ? 1 : -1) * (new Date(sortStringA) - new Date(sortStringB));
                        } else {
                            return (ascending ? 1 : -1) * sortStringA.localeCompare(sortStringB);
                        }
                    });

                    container.html('');
                    container.append(elems);
                    updateAboveMaxClasses(container);

                    $(this).data('ascending', ascending);
                });

                //WHEN YOU CLICK A LINK, OPEN ALL PANELS AND SMOOTH SCROLL TO MATCHING ID
                $('.circular-menu-item-link').click(function () {
                    var target = $(this).data('target');
                    setTimeout(function () {
                        $('.no-rail-layout-row').scrollTop($(target).offset().top);
                    }, 400);
                });

                //Get the document row for an element within a document row 
                function getDocumentContainerElement(element) {
                    return $(element).closest('.my-documents-tile-document-row');
                }

                /*Changes when clicking show more*/
                $(".my-documents-tile-show-more,.my-documents-tile-show-less").click(function () {
                    $(this)
                        .closest('.my-documents-tile-section')
                        .toggleClass('my-documents-tile-section-show-subset');
                });

                function updateAllAboveMaxClasses() {
                    $('.my-documents-tile-document-rows').each(function (i, v) {
                        updateAboveMaxClasses(v);
                    });
                }

                /**
                 * Add/Remove the above-max class as necessary. This causes
                 * docs beyond the 5th to be hidden.
                 * @param {any} container The .my-documents-tile-document-rows element to have its children adjusted
                 */
                function updateAboveMaxClasses(container) {
                    $(container)
                        .find('.my-documents-tile-document-row')
                        .removeClass('my-documents-tile-document-row-above-max')
                        .filter(':not(.my-documents-tile-document-row-year-filtered-out):not(.my-documents-tile-document-row-search-filtered-out)')
                        .slice(5)
                        .addClass('my-documents-tile-document-row-above-max');

                    $(container)
                        .closest('.my-documents-tile-section')
                        .toggleClass('my-documents-tile-section-lt-max', $(container).find('.my-documents-tile-document-row-above-max:not(.my-documents-tile-document-row-year-filtered-out):not(.my-documents-tile-document-row-search-filtered-out)').length <= 5);
                }

                initialized = true;
            }
        }
})();