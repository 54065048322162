(function () {
    function ExecuteSearch() {

        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        };

        var form = $("#partSearch");

        if (!$(form) || !$(form).length)
            return;

        $(form).validate();
        if (!$(form).valid()) {
            return;
        }
        $(".loader,.transaction-loading").addClass('active');
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: '/Impersonation/ParticipantSearchTableHandler',
            dataType: 'json',
            data: JSON.stringify({
                SelectCaseId: $('#SelectCaseId').val(),
                EmployeeId: $('#EmployeeId').val(),
                FirstName: $('#FirstName').val(),
                LastName: $('#LastName').val(),
                Last4SSN: $('#Last4SSN').val(),
            }), 
            success: function (data, textStatus, jqXHR) {
                $("#errorMessage").text('');
                var contents = '';
                $.each(data, function (index, value) {
                    contents += '<tr class="clickableRow" tag="' + value.Id + '">'
                        + '<td>' + value.EmployeeId + '</td>'
                        + '<td>' + value.Last4SSN + '</td>'
                        + '<td>' + value.FirstName + '</td>'
                        + '<td>' + value.LastName + '</td>'
                        + '<td>' + value.EEClassName + '</td>'
                        + '</tr>';
                });
             
                $('#resultTable').children('tbody').html(contents);
                $(".loader,.transaction-loading").removeClass('active');
                $('.tableScroll tr').click(function () {
                    var selectedId = $(this).attr("tag");
                    var $form = $("<form action='/Impersonation/ImpersonateSelectedParicipant' method='POST'><input type='hidden' name='selectedParticipantId' value='" + selectedId + "' /></form>");
                    $(document.body).append($form);
                    $form.submit();
                    $(".loader,.transaction-loading").addClass('active');
                });
            }
        });
    }
    

    $(document).ready(function () {
        $('input[type=button][value=Search]').click(ExecuteSearch);
        $('.form-control').keypress(function (e) {
            if (e.which === 13) {
                ExecuteSearch();
                return false;
            }
        });
    });
})();