let faqTileJS = (function () {
    let initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            $(window).on('resize', function() {
                if(window.innerWidth > 1199.98) {
                    $(".faq-overlay").css('display', '');
                    $(".faq-mobile-button-container").css('display', '');
                    $("html").css("overflow", "");
                    $(".faq-mobile-search-container").css("display", "");
                    $(".faq-tile").css("margin-top", "");
                }
            });

            /*User Search*/
            const question = $(".faq-tile-question");
            const answers = $(".faq-tile-answer");
            const topicTitle = $(".faq-tile-topicTitle");
            const userInput = $(".faq-search-field");

            //Filter Through Search
            userInput.keyup(function () {
                const word = this.value;

                //Sync the 2 search fields
                $(".faq-search-field").val(word);

                //Shows/hides topic names
                if (word !== '') {
                    for (let i = 0; i < topicTitle.length; i++) {
                        $(topicTitle[i]).addClass('d-none');
                    }
                } else {
                    for (let i = 0; i < topicTitle.length; i++) {
                        $(topicTitle[i]).removeClass('d-none');
                    }
                }

                //Shows/hides questions and answers
                for (let i = 0; i < question.length; i++) {
                    const questionTitle = question[i];
                    const answer = $(answers[i]).text().toLowerCase();
                    const titleText = question[i].innerText.trim().toLowerCase();

                    if (!titleText.includes(word) && !answer.includes(word)) {
                        questionTitle.classList.add('d-none');
                        $(answers[i]).addClass('d-none');
                        //Hides Read More/Less
                        if(answer.length > 490 || $(answers[i]).data("li-count") > 3) {
                            $(answers[i]).siblings('.faq-tile-read-more').addClass('d-none');
                            $(answers[i]).siblings('.faq-tile-read-less').addClass('d-none');
                        }
                    } else {
                        questionTitle.classList.remove('d-none');
                        $(answers[i]).removeClass('d-none');

                        if(answer.length > 490 || $(answers[i]).data("li-count") > 3) {
                            $(answers[i]).siblings('.faq-tile-read-less').trigger('click');
                        }
                    }
                }
            });

            $('.faq-tile-read-more').click(function() {
                $(this).addClass('d-none');
                $(this).siblings('.faq-tile-read-less').removeClass('d-none');
                $(this).prev().addClass('faq-line-clamp-unset');
            });

            $('.faq-tile-read-less').click(function() {
                $(this).addClass('d-none');
                $(this).siblings('.faq-tile-read-more').removeClass('d-none');
                $(this).prev().prev().removeClass('faq-line-clamp-unset');

            });

            $('.faq-mobile-filter-button').click(function() {
                $(".faq-overlay").css("display", "block");
                $(".faq-mobile-button-container").css("display", "none");
                $("html").css("overflow", "hidden");
            });

            $('.faq-overlay-close-button').click(function() {
                $(".faq-overlay").css("display", "");
                $(".faq-mobile-button-container").css("display", "");
                $("html").css("overflow", "");
            });

            $('.faq-overlay-list-item').click(function() {
                const target = $(this).attr('id');
                $("html").css("overflow", "");
                $('html, body').animate({
                    scrollTop: $('#'+ target).offset().top - 70
                });
                $('.faq-overlay-close-button').trigger('click');
            });

            $('.faq-mobile-search-button').click(function() {
                $(".faq-mobile-button-container").css("display", "none");
                $(".faq-mobile-search-container").css("display", "flex");
                $(".faq-tile").css("margin-top", "80px");

                $(".faq-mobile-search-container").find('.faq-search-field').focus();
            });

            $('.faq-mobile-search-close').click(function() {
                $(".faq-mobile-button-container").css("display", "");
                $(".faq-mobile-search-container").css("display", "");
                $(".faq-tile").css("margin-top", "");
            });

            initialized = true;
        }
    }
})();