var ExploreBenefitsTileJS = (function() {
    var initialized = false;
    var storedvalue = false;

        return {
            Init: function () {
                if (initialized) {
                    return;
                }

                calculateExactHeight();

                function calculateExactHeight() {
                    var headerHeight = $('.header-wrapper').height();
                    var combinedHeightOfContainerMinusX = headerHeight - 25;
                    $(".category-content-container").css({ 'top': headerHeight + 'px' });
                    $(".explore-category-mobile-buffer-bar").css({ 'top': headerHeight + 'px' });
                    $(".explore-category-mobile-close").css({ 'top': combinedHeightOfContainerMinusX + 'px' });
                }

                function isMobile() {
                    return typeof window.matchMedia == 'function'
                        && matchMedia('(max-width: 1199.98px)').matches;
                }

                //Hide Explore Benefits
                function hideExploreContent() {
                    $('.explore-benefits-content-container').hide();
                    $('.explore-benefits-content-container-instance').hide();
                    $('.explore-category-mobile-buffer-bar').show();
                }

                //Show Explore Benefits
                function showExploreContent() {
                    $('.explore-benefits-content-container').show();
                    $('.explore-benefits-content-container-instance').show();
                    $('.explore-category-mobile-buffer-bar').hide();
                }

                //Hide Toolbar
                function hideToolBar() {
                    $('.category-content-toolbar').hide();
                }

                //Show Toolbar
                function showToolBar() {
                    $('.category-content-toolbar').show();
                }

                //Hide All Categories On Load
                function hideAllCategoryContent() {
                    $('.explore-category-mobile-close').hide();

                    $('.category-content-container').each(function () {
                        $(this).hide();
                    });
                }
                //Open Up Category On Click
                $(".explore-benefits-tile-card").click(function () {
                    var containerID = $(this).attr('data-target');
                    hideAllCategoryContent();
                    hideExploreContent();
                    showToolBar();
                    $(containerID).show();
                    $(".explore-category-select").val(($(this).text().trim()));
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                    if (isMobile())
                        $('.explore-category-mobile-close').show();
                });

                //Go Back to Home Page
                $('.explore-category-go-back, .explore-category-mobile-close').click(function () {
                    hideToolBar();
                    hideAllCategoryContent();
                    showExploreContent();
                    $('.explore-category-mobile-close').hide();
                });

                //Change in Select Box = Change in Page Viewed
                $('.explore-category-select').change(function () {
                    var containerID = ($(this).find(':selected').attr('data-target'));
                    hideAllCategoryContent();
                    hideExploreContent();
                    showToolBar();
                    $(containerID).show();
                });

                //Hide Description if There Isn't One
                if ($('.explore-benefits-content-container-description').text() == '') {
                    $('.explore-benefits-content-container-description').hide();
                }

                window.onresize = function () {
                    calculateExactHeight();
                    if (storedvalue == isMobile())
                        return;


                    if (isMobile()) {
                        var arr = $('.category-content-container').get();

                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].style.display !== 'none') {
                                $('.explore-category-mobile-close').show();
                                break;
                            }
                        }
                    }
                    else {
                        $('.explore-category-mobile-close').hide();
                    }

                    storedvalue = isMobile();
                }

                // on page load
                hideAllCategoryContent();
                hideToolBar();
                $('.explore-category-mobile-buffer-bar').hide();

                storedvalue = isMobile();
                initialized = true;
            }
        }
})();