(function () {

    var initialInputValues = [];

    function isFormDirty() {
        var isDirty = false;
        $("#update-contact-info-form input:not([type=hidden])").each(function () {
            var value = $(this).is(':checkbox') ? $(this).is(':checked') : $(this).val();
            if ($(this).hasClass("phoneNumber")) {
                value = value.replace(/\D/g, '');
            }
            if (initialInputValues[$(this).attr("name")] !== value) {
                isDirty = true;
                return false;
            }
        });
        return isDirty;
    }


    $(document).ready(function () {

        if ($("#update-contact-info-modal").length) {
            $("#update-contact-info-modal").modal({ backdrop: 'static', keyboard: false });
        }
        

        $("#update-contact-info-form input:not([type=hidden])").each(function () {
            initialInputValues[$(this).attr("name")] = $(this).is(':checkbox') ? $(this).is(':checked') : $(this).val();
        });

        $(".phoneNumber").each(function () {
            $(this).val(Titania.Models.PhoneNumber.Format($(this).val()));
        });


        $('#update-contact-info-form input').keyup(debounce(function () {
            updateForm();
        }));

        $('#update-contact-info-form input[type="checkbox"]').change(debounce(function () {
            updateForm();
        }));

        function updateForm() {
            var formIsDirty = isFormDirty();

            $(".update-contact-info-modal-info-up-to-date-btn").prop('disabled', formIsDirty);

            formIsDirty ? $(".update-contact-info-modal-info-up-to-date-btn").removeClass("active") : $(".update-contact-info-modal-info-up-to-date-btn").addClass("active");
            updateSubmitButton(formIsDirty, true);
        }


        $("#update-contact-info-form").submit(function (e) {

            if (!validateAll(false)) {
                return false;
            }

            if ($("#update-contact-info-modal").length) {
                $('#update-contact-info-modal').modal('hide');
            }
        });


        $(".phoneNumber").on('keyup', function () {
            $(this).val(Titania.Models.PhoneNumber.Format($(this).val()));
        });

        function convertStringToBool(value) {
            return value && value.toLowerCase() === "true";
        }

        function validateAll(silent) {
            if (silent === undefined) {
                silent = false;
            }
            var workEmailError = $('#ParticipantPersonalInfo_WorkEmail').length ? validateEmail($('#ParticipantPersonalInfo_WorkEmail').val(), convertStringToBool($("#workEmail_isRequired").val())) : "";
            var personalEmailError = $('#ParticipantPersonalInfo_PersonalEmail').length ? validateEmail($('#ParticipantPersonalInfo_PersonalEmail').val(), convertStringToBool($("#personalEmail_isRequired").val())) : "";
            var workPhoneError = $('#ParticipantPersonalInfo_WorkCellPhone').length ? validatePhone($('#ParticipantPersonalInfo_WorkCellPhone').val(), convertStringToBool($("#workPhone_isRequired").val())) : "";
            var personalPhoneError = $('#ParticipantPersonalInfo_PersonalCellPhone').length ? validatePhone($('#ParticipantPersonalInfo_PersonalCellPhone').val(), convertStringToBool($("#personalCellPhone_isRequired").val())) : "";

            if (!silent) {
                $("#work-email-error").text(workEmailError);
                $("#personal-email-error").text(personalEmailError);
                $("#work-phone-error").text(workPhoneError);
                $("#personal-phone-error").text(personalPhoneError);
            }

            return workEmailError.length === 0 && personalEmailError.length === 0 && workPhoneError.length === 0 && personalPhoneError.length === 0;
        }

        $('#ParticipantPersonalInfo_WorkEmail').on('input', debounce(function () {
            $("#work-email-error").text(validateEmail($('#ParticipantPersonalInfo_WorkEmail').val()));
        }));

        $('#ParticipantPersonalInfo_PersonalEmail').on('input', debounce(function () {
            $("#personal-email-error").text(validateEmail($('#ParticipantPersonalInfo_PersonalEmail').val()));
        }));

        $('#ParticipantPersonalInfo_WorkCellPhone').on('input', debounce(function () {
            $("#work-phone-error").text(validatePhone($('#ParticipantPersonalInfo_WorkCellPhone').val()));
        }));

        $('#ParticipantPersonalInfo_PersonalCellPhone').on('input', debounce(function () {
            $("#personal-phone-error").text(validatePhone($('#ParticipantPersonalInfo_PersonalCellPhone').val()));
        }));


        function updateSubmitButton(formIsDirty, silent) {
            if (formIsDirty && isFormValid(silent)) {
                $("#update-contact-info-modal-info-update-btn").addClass("active");
                $("#update-contact-info-modal-info-update-btn").prop('disabled', false);
                return;
            }
            $("#update-contact-info-modal-info-update-btn").removeClass("active");
            $("#update-contact-info-modal-info-update-btn").prop('disabled', true);
        }

        function isFormValid(silent) {
            return $("#work-email-error").text() === "" &&
                $("#personal-email-error").text() === "" &&
                $("#work-phone-error").text() === "" &&
                $("#personal-phone-error").text() === "" &&
                validateAll(silent);
        }

        function validatePhone(phone, required) {
            phone = phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
            if (!phone || !phone.length) {
                return required ? "Phone Number Required" : "";
            }
            var regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            return regex.test(phone) ? "" : "Invalid Phone Number";
        }

        function validateEmail(email, required) {
            if (!email || !email.length) {
                return required ? "Email Required" : "";
            }
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email) ? "" : "Invalid Email";
        }

        function debounce(func) {
            var timeout;
            return function () {
                clearTimeout(timeout);
                timeout = null;
                timeout = setTimeout(func, 200);
            };
        }
    });
})();