var myInfoTileJS = (function () {
    var initiated = false;
    return {
        init: function () {
            if (initiated) {
                return;
            }

            //WHEN YOU CLICK A LINK, SMOOTH SCROLL TO MATCHING ID
            $('.circular-menu-item-link').click(function () {
                $('html, body').animate({
                    scrollTop: $('#' + $(this).data('target')).offset().top - 70
                });
            });

            $(window).bind('load', function () {
                $('.my-info-card__body').collapse("show");
            });
            
            $('.my-info-my-important-people-personal-info-container').click(function(e) {
                e.stopPropagation();
            });

            initiated = true;
        }
    }
})();