var fileUploadTileJS = (function () {

    var initialized = false;
    const BLOCK_SIZE = 256 * 1024;
    var fileCount = 0;
    var filesUploading = [];
    var uploadCardContent;

    function selectFile(file, fileContainer, queueNum) {
        fileContainer.parent(1).find('.file-name')[0].textContent = file.name;
        fileContainer.parent().parent().removeClass('upload-contents-hidden');

        var cardContent = fileContainer.closest('.submit-docs-card-content');
        //Open Collapsed Section
        if (!cardContent.find(".upload-collapse").first().hasClass("show")) {
            cardContent.find(".upload-collapse").first().collapse("show");
            cardContent.find(".no-docs").first().hide();
        }

        var isProofOfLifeEvent = cardContent.hasClass('proof-of-life-event');

        if (isFileValid(file)) {
            $('.submit-docs-tile-ext-error').hide();
            $.ajax({
                url: '/DocumentTile/GenerateUrlForFile',
                type: 'POST',
                data: { 'fileName': file.name, 'isProofOfLifeEvent': isProofOfLifeEvent },
                success: function (data) {
                    uploadFile(data.SASUrl, data.DocumentId, file, fileContainer, queueNum, isProofOfLifeEvent, data.ConfirmationNumber);
                }
            });
        }
    }

    function uploadFile(url, documentId, file, fileContainer, queueNum, isProofOfLifeEvent, confirmationNumber) {
        var cardContent = fileContainer.closest('.submit-docs-card-content');
        var uploadContainer = fileContainer.closest('.upload-container');
        var startPoints = [];
        var startPointer = 0;
        while (startPointer < file.size) {
            startPoints.push(startPointer);
            startPointer = Math.min(startPointer + BLOCK_SIZE, file.size);
        }
        startPoints.reduce((chain, currentStartPoint) => {
            if (filesUploading.indexOf(queueNum) !== -1) {
                return chain.then(_ =>
                    uploadBlobToBlobStorage(url + '&comp=block&blockid=' + getBlockId(currentStartPoint / BLOCK_SIZE), file.slice(currentStartPoint, Math.min(currentStartPoint + BLOCK_SIZE, file.size)))
                        .then(() => {
                            $("#progress-bar-filled").css("width", Math.min(currentStartPoint + BLOCK_SIZE, file.size) / file.size * 100);
                        }))
            }
        }, Promise.resolve()).then(() => {
            if (filesUploading.indexOf(queueNum) !== -1) {
                commitBlockList(url, file).then(() => {
                    var dependentId = cardContent.closest(".dependent").data("dependent-id");
                    var documentType = cardContent.closest(".submit-docs-card-content").data("document-type");
                    uploadContainer.data("url", url);
                    if (!isProofOfLifeEvent) {
                        $.ajax({
                            url: '/DocumentTile/SaveFile',
                            type: 'POST',
                            data: { 'documentId': documentId, 'dependentId': dependentId, 'documentType': documentType },
                            success: function (data) {
                                addFileToDom(data.Id, documentId, cardContent, uploadContainer, queueNum);
                            }
                        });
                    }
                    else {
                        sendEmailNotification(false, {
                            confNumber: confirmationNumber, filenames: [file.name]
                        });
                        addFileToDom('', documentId, cardContent, uploadContainer, queueNum);
                    }
                });
            }
        }).catch(function (err) {
            var error = {
                ErrorMessage: "Error while uploading document. Please try again."
            };
            if (isProofOfLifeEvent) {
                sendEmailNotification(true, {
                    confNumber: confirmationNumber, filenames: [file.name]
                });
            }
            showErrorModal(error);
        });
    }

    function addFileToDom(id, documentId, cardContent, uploadContainer, queueNum) {
        cardContent.find(".document-uploading").removeClass("document-uploading");
        uploadContainer.data("document-id", documentId);
        if (id) {
            uploadContainer.data("id", id);
            cardContent.addClass("completed");
            uploadContainer.find('.cancelUpload').removeClass('cancelUpload').addClass('deleteUpload');
            updateCompletionStatus(cardContent);
            updateCardSectionStatus(cardContent.closest(".dependent"));
        }
        else {
            uploadContainer.find('.cancelUpload').removeClass('cancelUpload').addClass('deleteFileFromAzure');
        }
        var index = filesUploading.indexOf(queueNum);
        if (index !== -1) {
            filesUploading.splice(index, 1);
        }
        var splitDocument = documentId.split('^');
        var existingDocHtml = `<div><input type="radio" class="select-document" name="selectedDocument" value="${documentId}" /><span>${splitDocument[splitDocument.length - 1]}</span></div>`;
        $('#existingDocuments').append(existingDocHtml);
    }

    function attachExistingFileToDependent(documentId, fileContainer) {
        var splitDocument = documentId.split('^');
        fileContainer.parent(1).find('.file-name')[0].textContent = splitDocument[splitDocument.length - 1];
        fileContainer.parent().parent().removeClass('upload-contents-hidden');

        $("#addExistingDocumentModal").modal('hide');

        var cardContent = fileContainer.closest('.submit-docs-card-content');
        //Open Collapsed Section
        if (!cardContent.find(".upload-collapse").first().hasClass("show")) {
            cardContent.find(".upload-collapse").first().collapse("show");
            cardContent.find(".no-docs").first().hide();
        }
        $('.submit-docs-tile-ext-error').hide();
        var dependentId = cardContent.closest(".dependent").data("dependent-id");
        var documentType = cardContent.closest(".submit-docs-card-content").data("document-type");
        $.ajax({
            url: '/DocumentTile/AttachExistingFileToDependent',
            type: 'POST',
            data: { 'documentId': documentId, 'dependentId': dependentId, 'documentType': documentType },
            success: function (data) {
                cardContent.find(".document-uploading").removeClass("document-uploading");
                cardContent.addClass("completed");
                updateCompletionStatus(cardContent);
                updateCardSectionStatus(cardContent.closest(".dependent"));
                fileContainer.closest('.upload-container').data("document-id", documentId);
                fileContainer.closest('.upload-container').data("id", data.Id);
                fileContainer.closest('.upload-container').find('.cancelUpload').removeClass('cancelUpload').addClass('deleteUpload');
            }
        });
    }

    function deleteFile(uploadContainer) {
        $(".transaction-loading, .loader").addClass("active");
        $.ajax({
            url: '/DocumentTile/DeleteUploadedDocument',
            type: 'POST',
            data: { 'id': uploadContainer.data('id') },
            success: function (data) {
                var cardContent = uploadContainer.closest(".submit-docs-card-content");
                var dependent = cardContent.closest(".dependent");

                uploadContainer.closest('.upload-contents').remove();
                $(".transaction-loading, .loader").removeClass("active");

                if (cardContent.find(".upload-container").length == 0) {
                    cardContent.find(".upload-collapse").first().collapse("hide");
                    cardContent.find(".no-docs").first().show();
                }

                if (cardContent.find(".upload-container:not([data-document-approval-status='denied'])").length == 0) {
                    cardContent.removeClass('completed')
                    updateCompletionStatus(cardContent);
                    updateCardSectionStatus(dependent);
                }
            }
        });
    }

    function deleteFileFromAzure(uploadContainer) {
        $(".transaction-loading, .loader").addClass("active");
        $.ajax({
            url: '/DocumentTile/DeleteDocumentFromAzure',
            type: 'POST',
            data: { 'documentId': uploadContainer.data('document-id') },
            success: function (data) {
                uploadContainer.closest('.upload-contents').remove();
                $(".transaction-loading, .loader").removeClass("active");
            }
        });
    }

    function isFileValid(file) {
        const allowedFileExtensions = ['.bmp', '.doc', '.docx', '.heic', '.heif', '.jpeg', '.jpg', '.msg', '.pdf', '.png', '.tif', '.tiff', '.xls', '.xlsx'];
        var isValid = allowedFileExtensions.indexOf(file.name.substring(file.name.lastIndexOf('.')).toLowerCase()) != -1;
        return isValid;
    }

    function getBlockId(blockId) {
        return btoa(Math.floor(blockId).toString().padStart(6, '0'));
    }

    function commitBlockList(sasURL, file) {
        var uri = sasURL + '&comp=blocklist';
        var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for(var i = 0; i< file.size / BLOCK_SIZE; i++) {
            requestBody += '<Latest>' + getBlockId(i) + '</Latest>';
        }
        requestBody += '</BlockList>';
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200 || xhr.status == 201) {
                        resolve(true);
                    } else {
                        reject(xhr.response);
                    }
                }
            }
            xhr.open("PUT", uri, true);
            xhr.setRequestHeader("x-ms-date", new Date().toISOString());
            xhr.setRequestHeader("x-ms-version", "2019-07-07");
            xhr.send(requestBody);
        });
    }

    function uploadBlobToBlobStorage(url, blob, onProgressMethod) {
        return new Promise((resolve, reject) => {
            try {
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4) {
                        if (xhr.status == 200 || xhr.status == 201) {
                            resolve(true);
                        } else {
                            reject(xhr.response);
                        }
                    }
                }
                if (onProgressMethod) {
                    xhr.upload.onprogress = onProgressMethod;
                }
                xhr.open("PUT", url, true);
                xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
                xhr.setRequestHeader("x-ms-date", new Date().toISOString());
                xhr.setRequestHeader("x-ms-version", "2019-07-07");
                xhr.send(blob);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    function updateCompletionStatus(cardContent) {
        var card = cardContent.closest(".submit-docs-card");
        var childrenCompleted = card.children(".completed").length;
        if (childrenCompleted > 0 && childrenCompleted >= parseInt(card.data("children-required"))) {
            card.addClass("completed");
            card.removeClass("incomplete");
        }
        else {
            card.addClass("incomplete");
            card.removeClass("completed");
        }
        var parentCard = card.parents(".submit-docs-card").first();
        if (parentCard.length > 0) {
            updateCompletionStatus(parentCard);
        }
    }

    function updateCardSectionStatus(dependent) {
        dependent.children('.section').each(function () {
            var card = $(this);
            var cardStatus = card.find('.submit-docs-card-status');
            var childrenCompleted = card.children(".completed").length;
            var childrenRequired = parseInt(card.data("children-required"));
            if (card.data('approval-status') != "approved" && card.data('approval-status') != "denied") {
                if (childrenCompleted >= childrenRequired) {
                    cardStatus.show();
                    cardStatus.html("Pending Approval");
                }
                else if (!dependent.hasClass("completed") && card.find('.upload-contents').length >= 1) {
                    cardStatus.show();
                    cardStatus.html(childrenRequired - childrenCompleted + " More Document" + ((childrenRequired - childrenCompleted) > 1 ? 's' : '') + " Required");
                }
                else {
                    cardStatus.hide();
                }
            }
        })
    }

    function getDocumentUrl(documentId) {
        return $.ajax({
            url: '/DocumentTile/GetDocumentUrl',
            type: 'POST',
            data: { 'documentId': documentId }
        });
    }
    
    function browserSupportsPDF() {
        return window.navigator.userAgent.indexOf("msie") == -1
            || hasActiveXObject('AcroPDF.PDF')
            || hasActiveXObject('PDF.PdfCtrl');
    }

    function hasActiveXObject(name) {
        try { return new ActiveXObject(name); } catch (e) { return false; }
    }

    function sendEmailNotification(didError, postData) {
        var navUrl = didError ? '/DocumentTile/_FileUploadError' : '/DocumentTile/_FileUploadComplete';
        $.ajax({
            url: navUrl,
            type: 'POST',
            data: postData
        });
    }

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            $(".submit-docs-card-content").each(function () {
                if ($(this).find(".upload-contents").length == 0) {
                    $(this).find(".no-docs").first().show();
                }
                else {
                    $(this).find(".no-docs").first().hide();
                }
            });

            $(".upload-contents").each(function () {
                $(this).closest('.upload-collapse').addClass('show');
                updateCompletionStatus($(this));
            });

            $(".dependent").each(function () {
                updateCardSectionStatus($(this));
            })

            $('.attach-files-zone').on('dragover', function (event) {
                event.stopPropagation();
                event.preventDefault();
                event.originalEvent.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
            });

            $("body").on('dragover', function (event) {
                event.stopPropagation();
                event.preventDefault();
                event.originalEvent.dataTransfer.dropEffect = 'none';
            });

            $(document).on('change', '.select-document', function () {
                $(".transaction-loading, .loader").addClass("active");
                var isPdf = $(this).data('is-pdf') == "True";
                $('#fileNotSupported').hide();
                $('#textPreview').hide();
                getDocumentUrl($(this).val()).then((url) => {
                    if (isPdf) {
                        console.log(browserSupportsPDF())
                        if (browserSupportsPDF()) {
                            $('#imagePreview').hide();
                            $('#pdfPreview').show();
                            $('#pdfPreview').attr('src', url);
                        }
                        else {
                            $('#file-not-supported').show();
                        }
                    }
                    else {
                        $('#pdfPreview').hide();
                        $('#imagePreview').show();
                        $('#imagePreview').attr('src', url);
                    }
                    $(".transaction-loading, .loader").removeClass("active");
                });
            });

            //Adding new documents
            $('.attach-files-zone').on('drop', function (event) {
                event.stopPropagation();
                event.preventDefault();
                //Grab upload container
                var cardContent = $(this).closest(".submit-docs-card-content");
                var myUploadDivs = cardContent.find('.upload-body').first();
                fileCount++;
                //Create an input control
                var newUploadControl = '<div class="upload-contents upload-contents-hidden" id="' + fileCount + '"><div class="row upload-container"><div class="col-6 file-browser"><span class="document-uploading"></span><span class="file-name">Browse for a file</span><i class="fas fa-trash-alt cancelUpload"></i></div><input type="file" class="soon-to-be-uploaded hiddenInput" accept=".bmp,.doc,.docx,.heic,.heif,.jpeg,.jpg,.msg,.pdf,.png,.tif,.tiff,.xls,.xlsx" /></div></div>';
                filesUploading.push(fileCount);

                myUploadDivs.append(newUploadControl);
                var newFile = cardContent.find(".soon-to-be-uploaded").last();
                selectFile(event.originalEvent.dataTransfer.files[0], newFile, fileCount);
            });

            //Adding new documents
            $('.attach-files-zone').click(function () {
                //Grab upload container
                var cardContent = $(this).closest(".submit-docs-card-content");
                var myUploadDivs = cardContent.find('.upload-body').first();
                fileCount++;
                //Create an input control
                var newUploadControl = '<div class="upload-contents upload-contents-hidden" id="' + fileCount + '"><div class="row upload-container"><div class="col-6 file-browser"><span class="document-uploading"></span><span class="file-name">Browse for a file</span><i class="fas fa-trash-alt cancelUpload"></i></div><input type="file" class="soon-to-be-uploaded hiddenInput" accept=".bmp,.doc,.docx,.heic,.heif,.jpeg,.jpg,.msg,.pdf,.png,.tif,.tiff,.xls,.xlsx" /></div></div>';
                filesUploading.push(fileCount);

                myUploadDivs.append(newUploadControl);

                //Grab most recent contol
                var newFile = cardContent.find(".soon-to-be-uploaded").last();

                //When user picks a file, populate the name field of it, and then show it to the user
                newFile.on('change', function () {
                    selectFile(this.files[0], $(this), fileCount);
                });

                //Click that invisible input
                newFile.click();
            });

            $(document).on('click', '#saveSelectedDocument', function () {
                var documentId = $('.select-document:checked').first().val();
                var cardContent = uploadCardContent;
                var myUploadDivs = cardContent.find('.upload-body').first();
                fileCount++;
                //Create an input control
                var newUploadControl = '<div class="upload-contents upload-contents-hidden" id="' + fileCount + '"><div class="row upload-container"><div class="col-6 file-browser"><span class="document-uploading"></span><span class="file-name">Browse for a file</span><i class="fas fa-trash-alt cancelUpload"></i></div><input type="file" class="soon-to-be-uploaded hiddenInput" accept=".bmp,.doc,.docx,.heic,.heif,.jpeg,.jpg,.msg,.pdf,.png,.tif,.tiff,.xls,.xlsx" /></div></div>';
                filesUploading.push(fileCount);

                myUploadDivs.append(newUploadControl);
                var newFile = cardContent.find(".soon-to-be-uploaded").last();
                attachExistingFileToDependent(documentId, newFile, fileCount)
            })

            $('.toggle-sections').click(function () {
                if ($(this).children('.fas').hasClass('fa-angle-up')) {
                    $(this).children('.fas').removeClass('fa-angle-up').addClass('fa-angle-down');
                    $(this).children('.toggle-sections-text').html('View Sections');
                    $(this).closest('.submit-docs-card').find('.section').hide();
                }
                else {
                    $(this).children('.fas').removeClass('fa-angle-down').addClass('fa-angle-up');
                    $(this).children('.toggle-sections-text').html('Hide Sections');
                    $(this).closest('.submit-docs-card').find('.section').show();
                }
            })

            //Deleting queued documents
            $(document).on('click', '.cancelUpload', function (event) {
                event.preventDefault();
                var cardContent = $(this).closest(".submit-docs-card-content");

                var fileNum = $(this).closest('.upload-contents').attr("id");
                var index = filesUploading.indexOf(parseInt(fileNum));
                if (index !== -1) {
                    filesUploading.splice(index, 1);
                }
                $(this).closest('.upload-contents').remove();

                if (cardContent.find(".upload-contents").length == 0) {
                    cardContent.find(".upload-collapse").first().collapse("hide");
                    cardContent.find(".no-docs").first().show();
                }
            });

            //Deleting uploaded documents
            $(document).on('click', '.deleteUpload', function (event) {
                event.preventDefault();
                deleteFile($(this).closest('.upload-container'))
            });

            $(document).on('click', '.deleteFileFromAzure', function (event) {
                event.preventDefault();
                deleteFileFromAzure($(this).closest('.upload-container'));
            });

            $(document).on('click', '.file-name', function () {
                getDocumentUrl($(this).closest('.upload-container').data('document-id')).then((url) => {
                    window.open(url, "_blank");
                })
            });

            $('.existing-documents-link').click(function () {
                uploadCardContent = $(this).closest(".submit-docs-card-content");
                $('#imagePreview').hide();
                $('#pdfPreview').hide();
                $('#fileNotSupported').hide();
                $('#textPreview').show();
                $("#addExistingDocumentModal").modal('show');
            });

            $("#submission-text > div").hide();
            $("#toggle-email-submission-text").click(function () {
                $("#submission-text > div").hide();
                $("#email-submission-text").show();
            });

            $("#toggle-fax-submission-text").click(function () {
                $("#submission-text > div").hide();
                $("#fax-submission-text").show();
            });

            $("#toggle-mail-submission-text").click(function () {
                $("#submission-text > div").hide();
                $("#mail-submission-text").show();
            });

            $('#openAlternateSubmissionsModal').click(function () {
                $("#alternateSubmissionsModal").modal('show');
            });

            $('#proofOfLifeEventModalLink').click(function () {
                $("#proofOfLifeEventModal").modal('show');
            });

            initialized = true;
        }
    }
})();