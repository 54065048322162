var ViewCurrentBenefitsTileJS = (function () {
    var initiated = false;
    return {
        init: function () {
            if (initiated) {
                return;
            }

            //PRINT THE PAGE
            $(".my-benefits-main-header-print").click(function () {
                window.print();
            });

            //WHEN YOU CLICK A LINK, SMOOTH SCROLL TO MATCHING ID
            $('.circular-menu-item-link').click(function () {
                $('html, body').animate({
                    scrollTop: $('#main-card-' + this.id).offset().top - 70
                });
            });

            //CHECK SIZE OF WINDOW AND DETERMINE IF CARDS OR OPENED OR NOT
            $(document).ready(function () {
                $('.current-benefits-card-info').collapse("show");
            });

            $('.view-current-benefits-collapse-all-button').click(function() {
                if($('.view-current-benefits-collapse-all-button').hasClass('collapsed')) {
                    $('.view-current-benefits-collapse-all-button').removeClass('collapsed');
                    $('.current-benefits-card-info').collapse('show');
                }
                else {
                    $('.view-current-benefits-collapse-all-button').addClass('collapsed');
                    $('.current-benefits-card-info').collapse('hide');
                }
            });

            initiated = true;
        }
    }
})();