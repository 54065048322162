var simpleTextTileJS = (function() {
    return {
        initialized: false,
        init: function() {
            if (this.initialized) {
                return;
            }

            var $el, $ps, $up, $l, $p, $m;

            // check to see if the element needs the "more" button
            $(".simple-text-tile").each(function () {
                if ($(this).find("div").outerHeight() > 120) {
                    $(this).addClass("simple-text-tile-max-height");
                } else {
                    $(this).find(".read-more").hide();
                }
            });

            $(".simple-text-tile p.read-more .button").click(function () {
                $el = $(this);
                $p = $el.parent();

                $up = $p.parent();
                $l = $up.find(".read-less");

                $ps = $up.find("p:not('.read-more')");

                $up.toggleClass("simple-text-tile-max-height");

                // fade out read-more
                $p.fadeOut();
                $l.fadeIn();

                // prevent jump-down
                return false;
            });

            $(".simple-text-tile p.read-less .button").click(function () {
                $el = $(this);
                $p = $el.parent();

                $up = $p.parent();
                $m = $up.find(".read-more");

                $up.toggleClass("simple-text-tile-max-height");

                $m.fadeIn();
                $p.fadeOut();

                return false;
            });

            this.initialized = true;
        }
    }
})();

